/*====================
    About Area
======================*/

/*font-family: industry, sans-serif;
font-weight: 700;
font-style: normal;



font-family: industry, sans-serif;
font-weight: 700;
font-style: italic;



font-family: industry, sans-serif;
font-weight: 400;
font-style: normal;



font-family: industry, sans-serif;
font-weight: 400;
font-style: italic;*/

#home {
  img.title-logo {
    width: 50%;
  }

  .home-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 100vh;
    position: relative;
  }

  .home-container-background {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-image: url(/assets/images/bg/cover_photo.png);
    background-size: cover;
    background-position: center;
  }

}

.image-overlay {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.35);
}

#home .partners {
  position: fixed;
  bottom: 4%;
  right: 5%;
  transition: 300ms;

  .title {
    margin-bottom: -1rem !important;
  }

  img {
    margin: 0 1rem;
  }
}

#about, .about-area {
  overflow: visible;
}

.about-area {
  padding: 120px 0 0;
  z-index: 10;
  position: relative;

  .rn-progress-bar {
    .single-progress {
      margin-top: 26px;
    }
  }
}

.rn-finding-us-area {
  .inner {
    position: relative;

    @media #{$lg-layout} {
      padding-left: 0;
      padding-top: 0;
      padding-bottom: 0;
      display: flex;
      flex-direction: column-reverse;
    }

    @media #{$md-layout} {
      padding-left: 0;
      padding-top: 0;
      padding-bottom: 0;
      display: flex;
      flex-direction: column-reverse;
    }

    @media #{$sm-layout} {
      padding-left: 0;
      padding-top: 0;
      padding-bottom: 0;
      display: flex;
      flex-direction: column-reverse;
    }

    .content-wrapper {
      .content {
        background: #101010;
        padding: 120px 100px;
        position: relative;
        z-index: 2;
        width: 800px;
        border-radius: 10px;

        @media #{$lg-layout} {
          padding: 60px 50px;
          width: 100%;
          border-radius: 0;
        }

        @media #{$md-layout} {
          padding: 60px 50px;
          width: 100%;
          border-radius: 0;
        }

        @media #{$sm-layout} {
          padding: 30px 30px;
          width: 100%;
          border-radius: 0;
        }

        h4 {
          color: #ffffff;
          font-size: 36px;
          font-weight: 500;
          margin-bottom: 20px;
          @media #{$sm-layout} {
            font-size: 26px;
            margin-bottom: 12px;
          }
        }

        p {
          font-size: 18px;
          color: #968b8b;
          font-weight: 300;
          margin-bottom: 41px;
          padding-right: 10%;
          @media #{$sm-layout} {
            margin-bottom: 24px;
            padding-right: 0;
          }
        }
      }
    }

    .thumbnail {
      position: absolute;
      top: 0;
      right: 0;
      height: 100%;

      @media #{$lg-layout} {
        position: static;
        height: 100%;
      }

      @media #{$md-layout} {
        position: static;
        height: 100%;
      }

      @media #{$sm-layout} {
        position: static;
        height: 100%;
      }

      img {
        border-radius: 10px;
        @media #{$lg-layout} {
          border-radius: 0;
        }
        @media #{$md-layout} {
          border-radius: 0;
        }
        @media #{$sm-layout} {
          border-radius: 0;
        }
      }
    }
  }
}

.about-inner {
  @media #{$md-layout} {
    padding-top: 40px;
  }
  @media #{$sm-layout} {
    padding-top: 40px;
  }

  .icon {
    display: inline-block;
    width: 60px;
    height: 60px;
    background: var(--color-primary);
    border-radius: 100%;
    text-align: center;
    color: #fff;
    line-height: 58px;
    font-size: 27px;
    margin-bottom: 17px;
    padding-right: 3px;


    svg {
      stroke-width: 1;
    }
  }

  .section-title {
    h2 {
      &.title {
        line-height: 1.3;
        margin-bottom: 35px;
      }
    }

    p {
      &.description {
        font-weight: 300;
        color: var(--color-gray);

        a {
          color: rgba(29, 29, 36, 1);

          &:hover {
            color: var(--color-primary);
          }
        }
      }
    }

  }
}

.about-images {
  position: relative;
  display: flex;
  align-items: center;
  transform: translateY(20%);

  .about-main-images {
    width: 40%;
  }

  .about-image {
    &.about-1 {
      width: 30%;
    }

    &.about-2 {

    }

    &.about-3 {
      width: 30%;
    }

    &.about-4 {

    }

    &.about-trex {
      position: absolute;
      top: 13%;
      left: 13%;
      width: 20%;

    /*  @media #{$sm-layout} {
        top: 10%;
        left: 10%;
        width: 40%;
      }*/
    }

    &.about-logo {
      position: absolute;
      bottom: 16%;
      right: 16%;
      width: 20%;

    /*  @media #{$sm-layout} {
        bottom: 10%;
        right: 10%;
        width: 40%;
      }*/
    }
  }
}


.about-wrapper {
  .thumbnail {
    position: relative;
    z-index: 2;

    img {
      border-radius: 8px;
      box-shadow: none;

    }
  }
}

.about-position-top {
  @media #{$lg-layout} {
    padding-top: 120px;
  }
  @media #{$md-layout} {
    padding-top: 80px;
  }
  @media #{$sm-layout} {
    padding-top: 60px;
  }

  .thumbnail {
    margin-top: -130px;
    @media #{$lg-layout} {
      margin-top: 0;
    }
    @media #{$laptop-device} {
      margin-top: -89px;
    }
    @media #{$md-layout} {
      margin-top: 0;
    }
    @media #{$sm-layout} {
      margin-top: 0;
    }
  }
}

.about-us-list {
  margin-top: 50px;
  @media #{$md-layout} {
    margin-top: 30px;
  }
  @media #{$sm-layout} {
    margin-top: 30px;
  }

  h3 {
    &.title {
      font-size: 30px;
      @extend %fontWeight500;
      margin-bottom: 20px;
      @media #{$sm-layout} {
        font-size: 26px;
        margin-bottom: 8px;
      }
    }
  }

  p {
    font-weight: 300;
    padding-right: 10%;
  }
}

.rn-content-box-style--1 {
  .content {
    text-align: left;
    padding: 0 120px;
    @media #{$md-layout} {
      padding: 0 60px;
    }
    @media #{$sm-layout} {
      padding: 0 40px;
    }

    p {
      &.subtitle {
        color: $heading-color;
        font-size: 16px;
        font-family: 'Saira Semi Condensed', sans-serif;
        font-weight: 400;
        padding: 8px 25px;
        display: inline-block;
        border-radius: 4px;

        span {
          color: var(--color-primary);
        }
      }
    }

    h2 {
      margin-top: 11px;
      margin-bottom: 20px;
      font-size: 36px;
      line-height: 50px;
      font-weight: 500;
      margin-bottom: 20px !important;
      @media #{$lg-layout} {
        margin-top: 7px;
      }
      @media #{$md-layout} {
        margin-top: 7px;
      }
      @media #{$sm-layout} {
        margin-top: 7px;
      }
    }

    p {
      color: rgba(29, 29, 36, 0.75);
      font-size: 18px;
      line-height: 30px;
    }
  }
}

// About Style Two
.rm-about-style-2 {
  .about-inner {
    .section-title {
      h2 {
        &.title {
          line-height: 1.3;
          margin-bottom: 26px;
        }
      }

      p {
        &.description {
          font-weight: 300;
          color: var(--color-gray);

          a {
            color: rgba(29, 29, 36, 1);

            &:hover {
              color: var(--color-primary);
            }
          }
        }
      }
    }
  }
}


// PROGRAM SECTION

.program-area {
  padding: 120px 0 100px;

  .program-area-title {

  }
}

.bg_color--primary {
  background: var(--color-primary);
  z-index: 9;
  position: relative;

  .section-title {
    .subtitle {
      color: #ffffff;
      font-family: 'Saira Semi Condensed', sans-serif;
      font-weight: 400;
    }

    &.border-bottom {
      padding-bottom: 1.5rem;
      margin-bottom: 2.5rem;
      border-bottom: 1px solid #ffffff;
    }
  }

  h1, h2 {
    color: #ffffff;
  }
}

.bg_color--5 {
  position: relative;

  .section-title {
    .subtitle {
      color: #1B1B1B;
      font-family: 'Saira Semi Condensed', sans-serif;
      font-weight: 400;
    }

    &.border-bottom {
      padding-bottom: 1.5rem;
      margin-bottom: 2.5rem;
      border-bottom: 1px solid #1B1B1B;
    }
  }

  h1, h2 {
    color: #1B1B1B;
  }
}


.service-area {
  padding: 80px 0 100px;

  .service-area-title {

  }

  .trainers {
    display: flex;
  }

  .trainer-segment {
    width: 20%;
    padding: 0 0.275rem 2rem;

    @media #{$md-layout} {
      width: 33.3%;
    }
    @media #{$sm-layout} {
      width: 33.3%;
    }

    .trainer-name {
      padding-top: 0.3125rem;
    }

    .trainer-name, .trainer-position {
      color: #1B1B1B;
      margin-bottom: 0;
      font-family: 'Saira Semi Condensed', sans-serif;
      font-weight: 400;

      @media #{$sm-layout} {
        font-size: 14px !important;
      }

      @media #{$md-layout} {
        font-size: 14px !important;
      }
    }
  }

  .experiences {
    padding: 2% 5% 0 30%;

    h2 {
      border-top: 1px solid;
      padding-top: 30px;
    }
  }

  .services-images {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10rem 10% 10%;

    @media #{$md-layout} {
      padding: 5rem 10% 5%;
    }
    @media #{$sm-layout} {
      padding: 5rem 10% 5%;
    }

    .services-image {
      &.left, &.right {
        width: 30%;

        @media #{$md-layout} {
          width: 40%;
        }
        @media #{$sm-layout} {
          width: 35%;
        }
      }

      &.middle {
        width: 40%;
        margin-bottom: -10%;
        margin-left: 2rem;
        margin-right: 2rem;

        @media #{$md-layout} {
          width: 55%;
          margin-bottom: -35%;
        }
        @media #{$sm-layout} {
          width: 60%;
          margin-bottom: -25%;
        }
      }
    }
  }

  .backgrounds {
    display: flex;
    padding-top: 3rem;
  }

  .background-segment {
    width: 33.3%;
    padding: 0 1rem 5rem;

    @media #{$md-layout} {
      width: 50%;
    }
    @media #{$sm-layout} {
      width: 50%;
    }

    .background-place {
      color: #1B1B1B;
      font-family: 'Saira Semi Condensed', sans-serif;
      font-weight: 400;
      padding-top: 1.5rem;
      margin: 0;
    }

    .background-activity {
      color: #1B1B1B;
      font-family: 'Saira Semi Condensed', sans-serif;
      font-weight: 700;
      margin-bottom: 0;
    }
  }
}

.visit-us-area {
  .why-to-join-items {
    display: flex;
    justify-content: center;
    padding: 10rem 0 12rem;

    @media #{$md-layout} {
      flex-direction: column;
      align-items: center;
      padding: 5rem 0;
    }
    @media #{$sm-layout} {
      flex-direction: column;
      align-items: center;
      padding: 5rem 0;
    }

    .why-to-join-item {
      position: relative;
      width: 30%;
      max-width: 400px;
      margin: -1.5rem;

      @media #{$md-layout} {
        width: 80%;
        margin: 3.5rem 0;
      }
      @media #{$sm-layout} {
        width: 80%;
        margin: 3.5rem 0;
      }

      .elipse {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%)
      }

      p {
        color: #ffffff;
        font-family: 'Saira Semi Condensed', sans-serif;
        font-weight: 700;
        max-width: 65%;
        margin: auto;
        padding-top: 1rem;
      }
    }
  }

  .why-tcs {
    position: relative;

    p {
      color: #1B1B1B
    }

    h4.title {
      border-top: 1px solid;
      padding-top: 30px;
    }
  }

  .why-tcs-image {
    &.middle {
      margin-top: 4rem;
      width: 35%;
      @media #{$md-layout} {
        display: none;
      }
      @media #{$sm-layout} {
        display: none;
      }
    }

    &.left {
      width: 50%;
      left: -15rem;
      top: -5%;
      position: absolute;
      // transform: translateX(-15rem);

      @media #{$sm-layout} {
        position: relative;
        transform: none;
        width: 98%;
        left: 0;
      }

      @media #{$md-layout} {
        position: relative;
        transform: none;
        width: 98%;
        top: -5%;
      }

      @media #{$md-layout} {
        position: relative;
        transform: none;
        width: 98%;
      }
    }

    &.right {
      width: 350px;
      position: absolute;
      bottom: -50%;
      // transform: translateY(15rem);

      @media #{$md-layout} {
        display: none;
      }
      @media #{$sm-layout} {
        display: none;
      }
    }
  }
}

.application-area {

  .applications {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 2rem;

    @media #{$md-layout} {
      padding-top: 40px !important;
      padding-bottom: 40px;
      flex-direction: column;
      align-items: center;
    }
    @media #{$sm-layout} {
      padding-top: 40px !important;
      padding-bottom: 40px;
      flex-direction: column;
      align-items: center;
    }
  }

  .application-date-item {
    position: relative;
    border-radius: 2rem;
    padding: 2rem 3rem;
    background: #FDE8EC;
    color: #6D0016;
    width: 40%;

    @media #{$md-layout} {
      width: 100%;
    }
    @media #{$sm-layout} {
      width: 100%;
    }

    h1 {
      color: #6D0016 !important;
      text-align: center;
      margin: 0;
    }

    .application-description {
      font-size: 14px;
      padding-top: 1rem;
    }

    .switch {
      position: absolute;
      left: 50%;
      bottom: -25px;
      transform: translateX(-25px);
      cursor: pointer;

      svg {
        width: 40px;
      }
    }
  }
}

.partners-area {
  .partners {
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    justify-content: space-between;

    .partner-image {
      width: calc(20% - 2rem);
      margin: 1rem 1rem 2rem;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.bg_color--transparent {
  background: transparent;
  z-index: 9;
  position: relative;

  .section-title {
    .subtitle {
      color: #1B1B1B;
      font-family: 'Saira Semi Condensed', sans-serif;
      font-weight: 400;
    }

    &.border-bottom {
      padding-bottom: 1.5rem;
      margin-bottom: 2.5rem;
      border-bottom: 1px solid #1B1B1B !important;
    }
  }

  h1, h2 {
    color: #1B1B1B;
  }
}

.bg_color--secondary {
  background: linear-gradient(180deg, #FCEDF0 0%, #FCEDF0 26%, #F7F7F7 100%); // #fcf0ed
  z-index: 9;
  position: relative;

  .section-title {
    .subtitle {
      color: #1B1B1B;
      font-family: 'Saira Semi Condensed', sans-serif;
      font-weight: 400;
    }

    &.border-bottom {
      padding-bottom: 1.5rem;
      margin-bottom: 2.5rem;
      border-bottom: 1px solid #1B1B1B !important;
    }
  }

  h1, h2 {
    color: #1B1B1B;
  }
}

.program-expand-panel {
  padding: 2rem 0 2.5rem;

  @media #{$md-layout} {
    flex-direction: column;
  }
  @media #{$sm-layout} {
    flex-direction: column;
  }

  .col-6.detail {
    display: flex;

    @media #{$md-layout} {
      max-width: 100%;
    }
    @media #{$sm-layout} {
      max-width: 100%;
    }
  }

  .col-6 {
    @media #{$md-layout} {
      max-width: 100%;

      .subtitle {
        padding-bottom: 1rem;
      }
    }
    @media #{$sm-layout} {
      max-width: 100%;

      .subtitle {
        padding-bottom: 1rem;
      }
    }
  }

  .switch {
    display: flex;
    align-items: center;
    margin-right: 1rem;
    cursor: pointer;
  }

  .description p {
    transition: 300ms;
  }

  .divider {
    padding-top: 3rem;
  }

  .subtitle {
    max-width: 70%;
  }

  h1 {
    margin: 0;
  }

  p {
    color: #ffffff;
    font-family: 'Saira Semi Condensed', sans-serif;
    font-weight: 400;
  }
}

.bg_image--2 {
  background-position: top center;
  padding: 6rem 0;

  h2.title {
    padding: 0 0 2rem;
    color: #ffffff;
  }

  min-height: 400px;
}

.divider {
  width: 100%;
  padding-top: 6rem;
  border-bottom: 1px solid #ffffff;
}

.contact-container {
  padding: 4rem;
}

.hide-mobile {
  display: block;

  @media #{$small-mobile} {
    display: none;
  }

  @media #{$large-mobile} {
    display: none;
  }

  @media #{$sm-layout} {
    display: none;
  }

  @media #{$md-layout} {
    display: none;
  }
}

// LOADER
#preloader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #000000;
  z-index: 999;
}

#loader {
  display: block;
  position: relative;
  left: 50%;
  top: 50%;
  width: 150px;
  height: 150px;
  margin: -75px 0 0 -75px;
  border-radius: 50%;
  border: 3px solid transparent;
  border-top-color: #ffffff;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

#loader:before {
  content: "";
  position: absolute;
  top: 5px;
  left: 5px;
  right: 5px;
  bottom: 5px;
  border-radius: 50%;
  border: 3px solid transparent;
  border-top-color: #cd201f;
  -webkit-animation: spin 3s linear infinite;
  animation: spin 3s linear infinite;
}

#loader:after {
  content: "";
  position: absolute;
  top: 15px;
  left: 15px;
  right: 15px;
  bottom: 15px;
  border-radius: 50%;
  border: 3px solid transparent;
  border-top-color: #C8232C;
  -webkit-animation: spin 1.5s linear infinite;
  animation: spin 1.5s linear infinite;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.language-switch {
  cursor: pointer;
  padding: 1.125rem 0;
  color: #ffffff;

  span.active {
    color: var(--color-primary);
  }
}
